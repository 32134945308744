import 'slick-carousel'

import './hs.core.js'
// import './hs.chartjs.js'
// import './hs.circles.js'
// import './hs.clipboard.js'
// import './hs.countdown.js'
// import './hs.cubeportfolio.js'
// import './hs.datatables.js'
// import './hs.dropzone.js'
// import './hs.fancybox.js'
// import './hs.flatpickr.js'
// import './hs.ion-range-slider.js'
// import './hs.leaflet.js'
// import './hs.pwstrength.js'
// import './hs.selectpicker.js'
import './hs.slick-carousel.js'
// import './hs.summernote-editor.js'
// import './hs.tagify.js'
// import './hs.validation.js'

import HSHeader from './hs-header/src/js/hs-header.js'
import HSGoTo from './hs-go-to/src/js/hs-go-to.js'
import HSUnfold from './hs-unfold/src/js/hs-unfold.js'
import HSMegaMenu from './hs-mega-menu/src/js/hs-mega-menu.js'
// import HSShowAnimation from './hs-show-animation/src/js/hs-show-animation.js'
import HSStickyBlock from './hs-sticky-block/src/js/hs-sticky-block.js'
// import HSCounter from './hs-counter/src/js/hs-counter'
// import 'appear/dist/appear'
// import './vendor/cubeportfolio/js/jquery.cubeportfolio.min.js'
// import 'jquery-validation'
// import './vendor/dzsparallaxer/dzsparallaxer.dev.js'
// import AOS from 'aos'

// import './vendor/dzsparallaxer/dzsparallaxer.scss'
// import './vendor/cubeportfolio/css/cubeportfolio.css'
// import 'flag-icon-css/sass/flag-icon.scss'

// import 'bootstrap-select'
// import 'chart.js'
// import 'clipboard'
// import 'datatables'
// import 'dropzone'
// import 'flatpickr'
// import 'ion-rangeslider'
// import 'jquery'
// import 'jquery-countdown'
// import 'jquery-validation'
// import 'leaflet'
// import 'pwstrength-bootstrap/dist/pwstrength-bootstrap'
// import 'summernote'
// import 'tagify'
// import 'typed'

const onReady = () => {
  //   initialization of header
  var header = new HSHeader($('#header')).init();

  //   initialization of mega menu
  var megaMenu = new HSMegaMenu($('.js-mega-menu'), { desktop: { position: 'left' } }).init();

  //   initialization of unfold
  var unfold = new HSUnfold('.js-hs-unfold-invoker').init();

  //   initialization of form validation
  // $('.js-validate').each(function () {
  //   $.HSCore.components.HSValidation.init($(this), { rules: { confirmPassword: { equalTo: '#signupPassword' } } });
  // });

  //   initialization of show animations
  // $('.js-animation-link').each(function () {
  //   var showAnimation = new HSShowAnimation($(this)).init();
  // });

  //   initialization of counter
  // $('.js-counter').each(function () {
  //   var counter = new HSCounter($(this)).init();
  // });

  // initialization of sticky blocks
  if ($('.js-sticky-block').length) {
    $('.js-sticky-block').each(function () {
      var stickyBlock = new HSStickyBlock($(this)).init();
    });
  }

  //   initialization of sticky block
  // var cbpStickyFilter = new HSStickyBlock($('#cbpStickyFilter'));

  //   initialization of cubeportfolio
  // $('.cbp').each(function () {
  //   var cbp = $.HSCore.components.HSCubeportfolio.init($(this), { layoutMode: 'grid', filters: '#filterControls', displayTypeSpeed: 0 });
  // });

  // $('.cbp').on('initComplete.cbp', function () {
  //   //     update sticky block
  //   cbpStickyFilter.update();
  //   //     initialization of aos
  //   AOS.init({ duration: 650, once: true });
  // });

  // $('.cbp').on('filterComplete.cbp', function () {
  //   //     update sticky block
  //   cbpStickyFilter.update();
  //   //     initialization of aos
  //   AOS.init({ duration: 650, once: true });
  // });

  // $('.cbp').on('pluginResize.cbp', function () {
  //   //     update sticky block
  //   cbpStickyFilter.update();
  // });

  //   animated scroll to cbp container
  // $('#cbpStickyFilter').on('click', '.cbp-filter-item', function (e) {
  //   $('html, body').stop().animate({ scrollTop: $('#demoExamplesSection').offset().top }, 200);
  // });

  //   initialization of go to
  $('.js-go-to').each(function () {
    var goTo = new HSGoTo($(this)).init();
  });
}

$(document).ready(onReady)
$(document).on('turbolinks:load', onReady)

// if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) document.write('<script src="../../assets/vendor/polifills.js"><\/script>');
