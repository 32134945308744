import 'trumbowyg/dist/trumbowyg.min'
import 'trumbowyg/dist/langs/it.min'
import 'trumbowyg/dist/plugins/cleanpaste/trumbowyg.cleanpaste.min'

import TrumboIcons from '../assets/images/trumbowyg_icons.svg'

$.trumbowyg.svgPath = TrumboIcons

export default class TrumbowygEditor {
  constructor(selector) {
    $(selector).trumbowyg({
      lang: 'it',
      semantic: true,
      removeformatPasted: true,
      autogrow: true,
      // btns: [
      //   ['viewHTML'],
      //   ['undo', 'redo'],
      //   ['strong', 'em', 'del'],
      //   ['link'],
      //   ['insertImage'],
      //   ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
      //   ['unorderedList', 'orderedList'],
      //   ['horizontalRule'],
      //   ['removeformat'],
      //   ['fullscreen']
      // ]
    })
  }
}

