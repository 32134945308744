export function smoothScroll() {
  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
      e.preventDefault();
      if (this.getAttribute('href') !== '#'){
        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        });
      }
    });
  });
}