export function startHomeTabs() {
  $('#nosco-cloud').find("[data-direction]").click(
    function() {
      $('#nosco-cloud').addClass("hidden");
      $('#nosco-io').removeClass("hidden");
    }
  )

  $('#nosco-io').find("[data-direction]").click(
    function() {
      $('#nosco-io').addClass("hidden");
      $('#nosco-cloud').removeClass("hidden");
    }
  )

  $('#modello-standard').find("[data-switch]").click(
    function() {
      $('#modello-standard').addClass("hidden");
      $('#modello-nosco').removeClass("hidden");
    }
  )

  $('#modello-nosco').find("[data-switch]").click(
    function() {
      $('#modello-nosco').addClass("hidden");
      $('#modello-standard').removeClass("hidden");
    }
  )
}

export function mobileMenuCloser() {
  // $('.navbar-nav>li>a').on('click', function(){
  //   $('.navbar-collapse').collapse('hide');
  // });
}