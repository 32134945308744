import FontFaceObserver from 'fontfaceobserver'

const FONT_FAMILIES = {
  BRIX_SANS: 'BrixSans',
  TITILLIUM: 'Titillium Web',
  SOURCE_SANS_PRO: 'Source Sans Pro',
}

const FONT_PROPS = {
  [FONT_FAMILIES.BRIX_SANS]: {
    NAME: 'BrixSans',
    PAGE_SELECTORS: [
      'body.smart-working',
      'body.leanbit--nosco',
      'body.leanbit--solution',
    ],
    LOADED_CLASS: 'fonts-loaded--brix-sans',
    FONT_FACE_OPTIONS_LIST: [
      { weight: '200', style: 'normal' },
      { weight: 'normal', style: 'normal' },
      { weight: 'bold', style: 'normal' },
    ],
  },
  [FONT_FAMILIES.TITILLIUM]: {
    PAGE_SELECTORS: [
      'body.leanbit--cases',
      'body.leanbit--new',
      'body.leanbit--nosco',
      'body.leanbit--solution',
    ],
    LOADED_CLASS: 'fonts-loaded--titillium',
    FONT_FACE_OPTIONS_LIST: [
      { weight: '300' },
      { weight: '400' },
      { weight: '600' },
    ],
  },
  [FONT_FAMILIES.SOURCE_SANS_PRO]: {
    PAGE_SELECTORS: [
      'body.leanbit--cases-offcar',
    ],
    LOADED_CLASS: 'fonts-loaded--source-sans-pro',
    FONT_FACE_OPTIONS_LIST: [
      { weight: '900' },
    ],
  },
}

const SETTINGS = {
  TIMEOUT_MS: 3000,
}

class AsyncFontLoader {
  constructor() {
    this.firstInit = true
  }

  init() {
    if (!this.firstInit) return

    this.createObservers()

    this.firstInit = false
  }

  createObservers() {
    Object.values(FONT_FAMILIES).forEach(fontFamily => {
      if ($(FONT_PROPS[fontFamily].PAGE_SELECTORS.join(', ')).length > 0) {
        let observers = []
        if (document.documentElement.classList.contains(FONT_PROPS[fontFamily].LOADED_CLASS)) {
          return false
        }

        FONT_PROPS[fontFamily].FONT_FACE_OPTIONS_LIST.forEach(fontFaceOptions => {
          const obs = new FontFaceObserver(fontFamily, fontFaceOptions)
          observers.push(obs.load(null, SETTINGS.TIMEOUT_MS))
        })

        Promise.all(observers).then(function (observer) {
          // observer.forEach(function (font) {
          //   console.log(`${font.family} ${font.weight} loaded`)
          // })
          document.documentElement.classList.add(FONT_PROPS[fontFamily].LOADED_CLASS)
        }).catch(function (err) {
          console.warn('Some critical font are not available:', err)
        })
      }
    })
  }
}

export default AsyncFontLoader
